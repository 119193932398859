import React from 'react';

import { Button, StandardDialog } from '@attentive/picnic';

const DEFAULT_BODY_TEXT = 'Are you sure you want to proceed?';

interface AreYouSureDialogProps {
  isOpen: boolean;
  onOpenChange: () => void;
  bodyText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const AreYouSureDialog: React.FunctionComponent<AreYouSureDialogProps> = ({
  isOpen,
  onOpenChange,
  bodyText,
  onConfirm,
  onCancel,
}) => {
  return (
    <StandardDialog open={isOpen} onOpenChange={onOpenChange}>
      <StandardDialog.Content>
        <StandardDialog.Header>
          <StandardDialog.Heading>Are you sure?</StandardDialog.Heading>
        </StandardDialog.Header>
        <StandardDialog.Body>{bodyText ? bodyText : DEFAULT_BODY_TEXT}</StandardDialog.Body>
        <StandardDialog.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </StandardDialog.Footer>
      </StandardDialog.Content>
    </StandardDialog>
  );
};

export default AreYouSureDialog;
