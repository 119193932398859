import React, { Suspense, useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';

import { useCurrentCompanyId } from '@attentive/acore-utils';
import { Emoji, LoadingIndicator, Stack, Text } from '@attentive/picnic';

import { parseRulesInfoData } from './FetchCompanyKeywordRulesInfo';

import FetchCompanyKeywordRulesInfoTacticalLibQuery, {
  FetchCompanyKeywordRulesInfoTacticalLibQuery as QueryType,
} from './__generated__/FetchCompanyKeywordRulesInfoTacticalLibQuery.graphql';

const RulesDisplay: React.FC<{ queryRef: PreloadedQuery<QueryType>; label: string }> = ({
  queryRef,
  label,
}) => {
  const queryData = usePreloadedQuery(FetchCompanyKeywordRulesInfoTacticalLibQuery, queryRef);
  const data = parseRulesInfoData(queryData);

  return data ? (
    <Stack>
      <Text>
        <b>{label}</b>
      </Text>
      {Array.from(data.rulesLegend.entries()).map(([key, value]) => (
        <Text key={key} css={{ mt: 0 }}>
          {key}: {value}
        </Text>
      ))}
      <Text>
        <b>Rule Expression:</b> <br /> {data.rulesExpression}
      </Text>
    </Stack>
  ) : (
    <Text css={{ minWidth: 200, textAlign: 'center' }}>
      Nothing to see here <Emoji label="eyes">👀</Emoji>
    </Text>
  );
};

export const RulesDisplayContainer: React.FC<{ keywordId: string; label: string }> = ({
  keywordId,
  label,
}) => {
  const companyId = useCurrentCompanyId();
  const [queryRef, loadQuery] = useQueryLoader<QueryType>(
    FetchCompanyKeywordRulesInfoTacticalLibQuery
  );

  useEffect(() => {
    loadQuery({
      companyId,
      keywordId,
    });
  }, [loadQuery, companyId, keywordId]);

  if (!queryRef) return null;

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <RulesDisplay queryRef={queryRef} label={label} />
    </Suspense>
  );
};
