import React from 'react';

import { Box, ButtonGroup, Select, Text } from '@attentive/picnic';

interface PaginationButtonsProps {
  loadNext: () => void;
  loadPrevious: () => void;
  loadPageSize: (v: number) => void;
  offset: number;
  totalCount: number;
  hasNext: boolean;
  pageSize: number;
}

export interface PaginationMetadata {
  offset: number;
  itemsPerPage: number;
  totalItems: number;
}

const getLabelText = ({ offset, itemsPerPage, totalItems }: PaginationMetadata) => {
  const offsetStart = offset;
  const offsetEnd = offset + itemsPerPage;

  const start = totalItems > 0 ? offsetStart + 1 : 0;
  const end =
    offsetEnd <= totalItems ? offsetEnd : offsetEnd - (itemsPerPage - (totalItems % itemsPerPage));

  return `Viewing ${start.toLocaleString()}-${end.toLocaleString()} of ${totalItems.toLocaleString()}`;
};

const PaginationButtons: React.FunctionComponent<PaginationButtonsProps> = ({
  loadNext,
  loadPrevious,
  loadPageSize,
  offset,
  totalCount,
  hasNext,
  pageSize,
}) => {
  const textCaption = getLabelText({ offset, itemsPerPage: pageSize, totalItems: totalCount });

  return (
    <Box
      css={{
        mt: '$space4',
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'center',
      }}
    >
      <Select
        size="small"
        css={{ width: '125px', mr: '$space4' }}
        value={pageSize}
        onChange={(val: number) => loadPageSize(val)}
      >
        <Select.Item value={10}>10 rows</Select.Item>
        <Select.Item value={50}>50 rows</Select.Item>
        <Select.Item value={100}>100 rows</Select.Item>
      </Select>
      <Text css={{ mt: '$space2', mr: '$space6' }} variant="caption">
        {textCaption}
      </Text>
      <ButtonGroup activeItem={null}>
        <ButtonGroup.IconItem
          name="ChevronLeft"
          description="Previous Page"
          disabled={offset === 0}
          onClick={loadPrevious}
        />
        <ButtonGroup.IconItem
          name="ChevronRight"
          description="Next Page"
          disabled={!hasNext}
          onClick={loadNext}
        />
      </ButtonGroup>
    </Box>
  );
};

export default PaginationButtons;
