/**
 * @generated SignedSource<<b82af6648d97d182d9c32be9998232e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateCustomCompanyKeywordInput = {
  attributeName?: string | null;
  companyId: string;
  keyword?: string | null;
  keywordId: string;
  keywordOrdinal?: number | null;
  mediaUrl?: string | null;
  messageText?: string | null;
  shouldForceSend?: boolean | null;
  shouldShortenLink?: boolean | null;
  subscriptionType?: string | null;
};
export type UpdateKeywordModalTacticalLibMutation$variables = {
  input: UpdateCustomCompanyKeywordInput;
};
export type UpdateKeywordModalTacticalLibMutation$data = {
  readonly updateCustomCompanyKeyword: {
    readonly __typename: string;
  } | null;
};
export type UpdateKeywordModalTacticalLibMutation = {
  response: UpdateKeywordModalTacticalLibMutation$data;
  variables: UpdateKeywordModalTacticalLibMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateCustomCompanyKeyword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateKeywordModalTacticalLibMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateKeywordModalTacticalLibMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "80b10c8c332b8f10ad560892827162bd",
    "id": null,
    "metadata": {},
    "name": "UpdateKeywordModalTacticalLibMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateKeywordModalTacticalLibMutation(\n  $input: UpdateCustomCompanyKeywordInput!\n) {\n  updateCustomCompanyKeyword(input: $input) {\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "37efa86ae14d1621c1845b6ba4ae5536";

export default node;
