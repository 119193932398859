import _FetchCompanyKeywordsLibFragment_query from "./__generated__/FetchCompanyKeywordsLibFragment_query.graphql";
import _FetchCompanyKeywordsTacticalLibQuery from "./__generated__/FetchCompanyKeywordsTacticalLibQuery.graphql";
import { graphql } from '@attentive/data';
export const parseData = queryData => {
  var _queryData$customComp;

  const keywords = (_queryData$customComp = queryData.customCompanyKeywords) === null || _queryData$customComp === void 0 ? void 0 : _queryData$customComp.edges.map(e => ({
    id: e.node.id,
    keyword: e.node.keywordInfo.keyword,
    messageText: e.node.messageInfo.messageText,
    attributeName: e.node.keywordInfo.attributeName,
    keywordOrdinal: e.node.keywordInfo.keywordOrdinal,
    hasSiblings: e.node.keywordInfo.hasSiblings,
    ruleCount: e.node.keywordInfo.ruleCount,
    enabled: e.node.keywordInfo.enabled,
    forceSend: e.node.messageInfo.forceSend,
    updated: e.node.updated.toString(),
    mediaUrl: e.node.messageInfo.mediaUrl,
    shortenLink: e.node.messageInfo.shortenLink
  }));
  return keywords || [];
};
export const PAGE_SIZE = 10;
_FetchCompanyKeywordsTacticalLibQuery;
_FetchCompanyKeywordsLibFragment_query;