/**
 * @generated SignedSource<<b5fb0ba3917f3fe202bb1504d13cb0cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FetchCompanyKeywordRulesInfoTacticalLibQuery$variables = {
  companyId: string;
  keywordId: string;
};
export type FetchCompanyKeywordRulesInfoTacticalLibQuery$data = {
  readonly keywordRulesInfoByKeywordId: {
    readonly companyKeywordId: number;
    readonly ruleCount: number;
    readonly rulesExpression: string;
    readonly rulesLegend: ReadonlyArray<{
      readonly key: string;
      readonly value: string;
    }>;
  } | null;
};
export type FetchCompanyKeywordRulesInfoTacticalLibQuery = {
  response: FetchCompanyKeywordRulesInfoTacticalLibQuery$data;
  variables: FetchCompanyKeywordRulesInfoTacticalLibQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "keywordId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "companyId",
        "variableName": "companyId"
      },
      {
        "kind": "Variable",
        "name": "keywordId",
        "variableName": "keywordId"
      }
    ],
    "concreteType": "KeywordRulesInfoPayload",
    "kind": "LinkedField",
    "name": "keywordRulesInfoByKeywordId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyKeywordId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ruleCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rulesExpression",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RulesLegendEntry",
        "kind": "LinkedField",
        "name": "rulesLegend",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchCompanyKeywordRulesInfoTacticalLibQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchCompanyKeywordRulesInfoTacticalLibQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f85fb2963943c92343e0f5be85f05c8c",
    "id": null,
    "metadata": {},
    "name": "FetchCompanyKeywordRulesInfoTacticalLibQuery",
    "operationKind": "query",
    "text": "query FetchCompanyKeywordRulesInfoTacticalLibQuery(\n  $companyId: ID!\n  $keywordId: ID!\n) {\n  keywordRulesInfoByKeywordId(companyId: $companyId, keywordId: $keywordId) {\n    companyKeywordId\n    ruleCount\n    rulesExpression\n    rulesLegend {\n      key\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "620d0b438c091a251e941dd567151d53";

export default node;
