import React from 'react';

import { TextInput, TextArea, FormField, InputGroup } from '@attentive/picnic';

export const KeywordTextInput: React.FunctionComponent<{
  label: string;
  value: string;
  onChange: (v: string) => void;
  keywordError: string | null;
}> = ({ label, value, onChange, keywordError }) => {
  return (
    <FormField>
      <FormField.Label>{label}</FormField.Label>
      <InputGroup>
        <TextInput
          required={true}
          css={{ width: '550px' }}
          name={label}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </InputGroup>
      <FormField.ErrorText>{keywordError}</FormField.ErrorText>
    </FormField>
  );
};

export const KeywordTextArea: React.FunctionComponent<{
  label: string;
  value: string;
  onChange: (v: string) => void;
  keywordError: string | null;
}> = ({ label, value, onChange, keywordError }) => {
  return (
    <FormField>
      <FormField.Label>{label}</FormField.Label>
      <InputGroup>
        <TextArea
          css={{ width: '550px', height: '150px' }}
          name={label}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </InputGroup>
      <FormField.ErrorText>{keywordError}</FormField.ErrorText>
    </FormField>
  );
};
