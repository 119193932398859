import React from 'react';
import { PreloadedQuery, usePreloadedQuery, useRefetchableFragment } from 'react-relay';

import { SortDirection } from '@attentive/data/types';
import { Box, Table } from '@attentive/picnic';

import { KeywordOrderBy } from '../../../constants/keywordTypes';
import {
  parseSortBy,
  parseSortDirection,
  setSortByKeyword,
  setSortByUpdated,
} from '../../../pages/CustomCompanyKeywords/helperFns';

import { parseData } from './FetchCompanyKeywords';
import KeywordsRow from './KeywordsRow';
import PaginationButtons from './PaginationButtons';

import Fragment, {
  FetchCompanyKeywordsLibFragment_query$key as FragmentType,
} from './__generated__/FetchCompanyKeywordsLibFragment_query.graphql';
import { FetchCompanyKeywordsLibFragment_query_refetchable as RefetchType } from './__generated__/FetchCompanyKeywordsLibFragment_query_refetchable.graphql';
import Query, {
  FetchCompanyKeywordsTacticalLibQuery as QueryType,
} from './__generated__/FetchCompanyKeywordsTacticalLibQuery.graphql';

interface KeywordsTableProps {
  queryRef: PreloadedQuery<QueryType>;
  companyId: string;
  search: string;
  sort: { get: KeywordOrderBy; set: React.Dispatch<React.SetStateAction<KeywordOrderBy>> };
  offset: { get: number; set: React.Dispatch<React.SetStateAction<number>> };
  pageSize: { get: number; set: React.Dispatch<React.SetStateAction<number>> };
}

const KeywordsTable: React.FunctionComponent<KeywordsTableProps> = ({
  queryRef,
  companyId,
  search,
  sort,
  offset,
  pageSize,
}) => {
  const queryData = usePreloadedQuery<QueryType>(Query, queryRef);
  const [data, refetch] = useRefetchableFragment<RefetchType, FragmentType>(Fragment, queryData);
  const keywords = parseData(data);

  const handleRefetch = (newOffset: number, newPageSize: number) => {
    const sortBy = parseSortBy(sort.get);
    const sortDirection = parseSortDirection(sort.get);
    refetch({
      offset: newOffset.toString(),
      companyId,
      search,
      sortBy,
      sortDirection,
      pageSize: newPageSize,
    });
    offset.set(newOffset);
    pageSize.set(newPageSize);
  };

  const loadPrevious = () => {
    const newOffset = offset.get - pageSize.get;
    handleRefetch(newOffset, pageSize.get);
  };

  const loadNext = () => {
    const newOffset = offset.get + pageSize.get;
    handleRefetch(newOffset, pageSize.get);
  };

  const loadPageSize = (newPageSize: number) => {
    // when page size changes we reset offset to 0
    handleRefetch(0, newPageSize);
  };

  const handleUpdate = () => {
    const sortBy = parseSortBy(sort.get);
    const sortDirection = parseSortDirection(sort.get);
    refetch(
      {
        offset: offset.get.toString(),
        companyId,
        search,
        sortBy,
        sortDirection,
        pageSize: pageSize.get,
      },
      {
        fetchPolicy: 'network-only',
      }
    );
  };

  const sortBy = parseSortBy(sort.get);
  const sortDirection = parseSortDirection(sort.get);

  return (
    <Box>
      <Table columns={[1.25, 4, 1.25, 1, 1, 0.5]} css={{ width: '100%' }}>
        <Table.HeaderRow>
          <Table.SortableHeaderCell
            onChange={() => setSortByKeyword(sortBy, sortDirection, sort.set)}
            ascending={sortBy === 'keyword' && sortDirection === SortDirection.SortDirectionAsc}
            isSortActive={sortBy === 'keyword'}
          >
            <Box css={{ overflow: 'hidden' }}>Keyword&emsp;</Box>
          </Table.SortableHeaderCell>
          <Table.HeaderCell>Message Text</Table.HeaderCell>
          <Table.HeaderCell>Attribute Name</Table.HeaderCell>
          <Table.HeaderCell>Rules</Table.HeaderCell>
          <Table.SortableHeaderCell
            onChange={() => setSortByUpdated(sortBy, sortDirection, sort.set)}
            ascending={sortBy === 'updated' && sortDirection === SortDirection.SortDirectionAsc}
            isSortActive={sortBy === 'updated'}
          >
            Updated
          </Table.SortableHeaderCell>
          <Table.HeaderCell />
        </Table.HeaderRow>
        <Table.Body>
          {keywords.map((keyword) => (
            <KeywordsRow key={keyword.id} keyword={keyword} afterUpdate={handleUpdate} />
          ))}
        </Table.Body>
      </Table>
      <PaginationButtons
        loadNext={loadNext}
        loadPrevious={loadPrevious}
        loadPageSize={loadPageSize}
        offset={offset.get}
        totalCount={data.customCompanyKeywords?.totalCount || 1}
        hasNext={data.customCompanyKeywords?.pageInfo.hasNextPage || false}
        pageSize={pageSize.get}
      />
    </Box>
  );
};

export default KeywordsTable;
