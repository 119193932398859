import { formatDistanceToNow } from 'date-fns';
import React, { useState } from 'react';

import {
  Badge,
  Box,
  ContainedLabel,
  Heading,
  Popover,
  Table,
  Text,
  Tooltip,
  styled,
  theme2021,
} from '@attentive/picnic';

import { CustomCompanyKeyword } from '../../../constants/keywordTypes';
import UpdateKeywordModal from '../KeywordModalForms/UpdateKeywordModal';

import MoreOptionsDropdown from './MoreOptionsDropdown';
import { RulesDisplayContainer as RulesDisplay } from './RulesDisplay';

const StyledBodyCell = styled(Table.BodyCell, {
  pr: 0,
});

const StyledBox = styled(Box, {
  overflow: 'hidden',
});

const Keyword: React.FC<{ keyword: CustomCompanyKeyword; textColor: string }> = ({
  keyword,
  textColor,
}) => {
  return (
    <>
      <Heading
        variant="sm"
        css={{
          color: textColor,
          overflowWrap: 'anywhere',
        }}
      >
        {keyword.keyword}&ensp;
      </Heading>
      {keyword.hasSiblings && (
        <Badge variant="standard" position="inline" css={{ mt: 1, ml: 1, color: textColor }}>
          {keyword.keywordOrdinal}
        </Badge>
      )}
    </>
  );
};

const AttributeName: React.FC<{ textColor: string; attrName: string | null }> = ({
  textColor,
  attrName,
}) => {
  const theme = {
    color: textColor,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };

  return (
    <Tooltip>
      <Tooltip.Trigger>
        <Text variant="caption" css={theme}>
          {attrName}
        </Text>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <Text variant="caption">{attrName}</Text>
      </Tooltip.Content>
    </Tooltip>
  );
};

const Rules: React.FC<{ keyword: CustomCompanyKeyword }> = ({ keyword }) => {
  const rulesCount = keyword.ruleCount;
  const [open, setOpen] = useState<boolean>(false);

  const buildLabel = (count: number | null): string => {
    if (count) {
      if (count > 1) {
        return `${count} Rules`;
      }
      return `${count} Rule`;
    }
    return 'None';
  };

  const variant = (count: number | null): 'decorative1' | 'neutral' => {
    if (count) {
      return 'decorative1';
    }
    return 'neutral';
  };

  const label = buildLabel(rulesCount);

  return (
    <Popover open={open}>
      <Popover.Anchor>
        <Box>
          <ContainedLabel
            onMouseEnter={() =>
              setTimeout(() => {
                setOpen(true);
              }, 200)
            }
            onMouseLeave={() =>
              setTimeout(() => {
                setOpen(false);
              }, 500)
            }
            variant={variant(rulesCount)}
          >
            {label}
          </ContainedLabel>
        </Box>
      </Popover.Anchor>
      <Popover.Content showCloseButton={false}>
        <RulesDisplay keywordId={keyword.id} label={label} />
      </Popover.Content>
    </Popover>
  );
};

const Updated: React.FC<{ textColor: string; updated: string }> = ({ textColor, updated }) => {
  const date = new Date(updated);
  const distanceToNow = formatDistanceToNow(date, { addSuffix: true })
    .replace(' hours', 'hrs')
    .replace(' hour', 'hr');

  return (
    <Tooltip>
      <Tooltip.Trigger>
        <Text variant="caption" css={{ color: textColor }}>
          {distanceToNow}
        </Text>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <Text variant="caption">{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}</Text>
      </Tooltip.Content>
    </Tooltip>
  );
};

const KeywordsRow: React.FunctionComponent<{
  keyword: CustomCompanyKeyword;
  afterUpdate: () => void;
}> = ({ keyword, afterUpdate }) => {
  const [isUpdateOpen, setIsUpdateOpen] = React.useState<boolean>(false);
  const textColor = keyword.enabled ? theme2021.colors.textDefault : theme2021.colors.grayscale300;

  return (
    <Table.BodyFocusableRow
      data-disable-focus
      onFocusCapture={(e) => !isUpdateOpen && e.target.blur()}
    >
      <StyledBodyCell onClick={() => setIsUpdateOpen(true)}>
        <Keyword keyword={keyword} textColor={textColor} />
      </StyledBodyCell>
      <StyledBodyCell onClick={() => setIsUpdateOpen(true)}>
        <StyledBox>
          <Text css={{ color: textColor }} variant="caption">
            {keyword.messageText}
          </Text>
        </StyledBox>
      </StyledBodyCell>
      <StyledBodyCell onClick={() => setIsUpdateOpen(true)}>
        <AttributeName textColor={textColor} attrName={keyword.attributeName} />
      </StyledBodyCell>
      <StyledBodyCell onClick={() => setIsUpdateOpen(true)}>
        <Rules keyword={keyword} />
      </StyledBodyCell>
      <StyledBodyCell onClick={() => setIsUpdateOpen(true)}>
        <Updated textColor={textColor} updated={keyword.updated} />
      </StyledBodyCell>
      <StyledBodyCell>
        <MoreOptionsDropdown
          afterUpdate={afterUpdate}
          enabled={keyword.enabled}
          keywordId={keyword.id}
        />
        <UpdateKeywordModal
          afterUpdate={afterUpdate}
          isOpen={isUpdateOpen}
          onOpenChange={() => setIsUpdateOpen(!isUpdateOpen)}
          initialKeyword={keyword}
        />
      </StyledBodyCell>
    </Table.BodyFocusableRow>
  );
};
export default KeywordsRow;
