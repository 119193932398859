import { SortDirection } from '@attentive/data/types';

import { KeywordOrderBy } from '../../constants/keywordTypes';

export const parseSortBy = (sort: KeywordOrderBy) => {
  switch (sort) {
    case KeywordOrderBy.KEYWORD_ASC:
    case KeywordOrderBy.KEYWORD_DESC:
      return 'keyword';
    case KeywordOrderBy.UPDATE_ASC:
    case KeywordOrderBy.UPDATE_DESC:
      return 'updated';
    default:
      return 'keyword';
  }
};

export const parseSortDirection = (sort: KeywordOrderBy) => {
  switch (sort) {
    case KeywordOrderBy.KEYWORD_ASC:
    case KeywordOrderBy.UPDATE_ASC:
      return SortDirection.SortDirectionAsc;
    case KeywordOrderBy.KEYWORD_DESC:
    case KeywordOrderBy.UPDATE_DESC:
      return SortDirection.SortDirectionDesc;
    default:
      return SortDirection.SortDirectionUnknown;
  }
};

export const setSortByKeyword = (
  sortBy: string,
  sortDirection: SortDirection,
  setSort: (val: KeywordOrderBy) => void
) => {
  if (sortBy !== 'keyword') {
    setSort(KeywordOrderBy.KEYWORD_ASC);
  }

  if (sortDirection === SortDirection.SortDirectionAsc) {
    setSort(KeywordOrderBy.KEYWORD_DESC);
  } else {
    setSort(KeywordOrderBy.KEYWORD_ASC);
  }
};

export const setSortByUpdated = (
  sortBy: string,
  sortDirection: SortDirection,
  setSort: (val: KeywordOrderBy) => void
) => {
  if (sortBy !== 'updated') {
    setSort(KeywordOrderBy.UPDATE_ASC);
  }

  if (sortDirection === SortDirection.SortDirectionAsc) {
    setSort(KeywordOrderBy.UPDATE_DESC);
  } else {
    setSort(KeywordOrderBy.UPDATE_ASC);
  }
};
